.category__tabs {
  padding: 24px;

  .ant-tabs-nav {
    .ant-tabs-tab {
      padding: 16px 0;

      &:hover {
        color: unset;
      }
    }

    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: $primary;
        font-weight: bold;
      }
    }

    .ant-tabs-ink-bar {
      background-color: $primary;
    }
  }
}
