$added: #52c41a;
$updated: #2f54eb;
$fixed: #fa8c16;

.changelog__log__description {
  color: #53576f;

  @include textTypography(Inter, 18px, normal, 400, 26px, 0, left);
}

.changelog__log__item {
  margin-bottom: 32px;

  .log__item__title {
    padding-left: 16px;
    margin-bottom: 19px;

    @include textTypography(Inter, 18px, normal, 600, 26px, 0, left);

    &___added {
      color: $added;
      border-left: 4px solid $added;
    }

    &___updated {
      color: $updated;
      border-left: 4px solid $updated;
    }

    &___fixed {
      color: $fixed;
      border-left: 4px solid $fixed;
    }
  }

  ul {
    list-style: none;

    li {
      &::before {
        content: '\2022';
        color: #53576f;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }

      color: #53576f;

      @include textTypography(Inter, 16px, normal, 400, 24px, 0, left);
    }
  }
}
