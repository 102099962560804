@import '../../textTypography';
@import '../../responsive';

.homepage__features {
  padding: 80px 0;
  background-color: $blue-lighter;

  .homepage__features__header {
    max-width: 956px;
    margin: auto;

    h1 {
      color: $text-blue-dark-light;

      @include textTypography(Inter, 46px, normal, 600, 54px, 0, center);
    }

    p {
      color: $text-blue-dark-lightest;

      @include textTypography(Inter, 18px, normal, 400, 26px, -0.3px, center);
    }
  }

  .container {
    .ant-tabs {
      .ant-tabs-nav {
        &::before {
          border: 0;
        }

        .ant-tabs-tab {
          margin: 0 12px;
        }

        .ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: $primary;
          }
        }

        .ant-tabs-tab-btn {
          color: $text-blue-dark-lightest;

          @include textTypography(Inter, 16px, normal, 400, 24px, 0, center);
        }

        .ant-tabs-ink-bar {
          background-color: $primary;
        }
      }
    }

    .ant-carousel {
      .slick-list {
        padding-left: 24px;

        .slick-track {
          display: flex !important;

          div {
            height: 100%;
          }

          .slick-slide {
            $marginWidth: 8px;
            height: inherit !important;
            padding-bottom: 10px;

            width: $marginWidth + 269px;

            @include responsive(mobile-large) {
              width: $marginWidth + 252px;
            }
            @include responsive(tablet) {
              width: $marginWidth + 344px;
            }
            @include responsive(pc-small) {
              width: $marginWidth + 299px;
            }
            @include responsive(pc) {
              width: $marginWidth + 270px;
            }
            @include responsive(pc-large) {
              width: $marginWidth + 370px;
            }
          }
        }
      }

      .slick-arrow {
        background: white !important;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 1px solid #d0d1d6;
        z-index: 2;
        color: $text-blue-dark-lightest !important;
        box-shadow: 0px 4px 6px 0px #0818231a, 0px 2px 4px 0px #0818230f;
        display: none !important;

        @include responsive(mobile-large) {
          display: flex !important;
          align-items: center;
          justify-content: center;
        }

        svg {
          width: 13px !important;
          height: 13px !important;
        }

        &.slick-prev {
          left: 60px;
        }

        &.slick-next {
          right: 60px;
        }

        &.slick-disabled {
          display: none !important;
        }
      }
    }
  }
}
