/* stylelint-disable selector-class-pattern */

/* stylelint-disable scss/at-import-partial-extension */
@import 'variables';
@import './components/Header';
@import './components/Homepage';
@import './components/Changelog';

* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

#nprogress .bar {
  background: $primary;
}

#nprogress .spinner-icon {
  border-top-color: $primary;
  border-left-color: $primary;
}

.ant-carousel {

  .slick-list {

    .slick-slide {
      pointer-events: all !important;
    }
  }
}

.ant-drawer-body,
.ant-drawer-header {
  border-radius: 0;
}

.ant-menu-submenu > .ant-menu .ant-menu-item .ant-menu-item-icon svg{
  font-size: 16px;
  width: 1em;
  height: 1em;
}