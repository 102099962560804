/* stylelint-disable no-descending-specificity */
@import 'sailor-ui/styles/responsive';
@import '../textTypography';

.ant-drawer {

  .ant-drawer-content-wrapper {
    width: 320px !important;

    .ant-drawer-header {
      background-color: #00003d;
      border: unset;

      .headerLogo {
        color: white;
      }

      .ant-drawer-close {
        color: #d0d1d6;
      }
    }

    .ant-drawer-body {
      padding: 0;
      overflow-x: hidden;
      background-color: #00003d;
      display: flex;
      flex-direction: column;

      .ant-menu-item {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #d0d1d6;

        a {
          color: #d0d1d6;
        }

        @include textTypography(Inter, 14px, normal, 400, 22px, 0, left);
      }

      .ant-menu-item-active {
        background-color: #00003d;
      }

      & > .ant-menu {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .ant-menu {
        background-color: #00003d;

        .ant-menu-submenu {
          color: #d0d1d6;

          i {
            display: none;
          }

          .ant-menu-sub {
            background-color: #030063;

            .ant-menu-item {
              color: #d0d1d6;
              padding-left: 24px !important;
            }
          }
        }

        .ant-menu-item-selected {
          background-color: unset;
          color: #d0d1d6;

          &::after {
            display: none;
          }
        }

        .ant-menu-item-group {

          .ant-menu-item-group-title {
            color: #737585;

            @include textTypography(Inter, 12px, normal, 600, 20px, 0, left);
          }
        }
      }
    }

    .ant-menu-inline .ant-menu-item-group-list .ant-menu-submenu-title,
    .ant-menu-inline .ant-menu-submenu-title {
      padding-right: 16px;
    }

    .ant-menu-inline.ant-menu-root .ant-menu-item> .ant-menu-title-content, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title> .ant-menu-title-content{

      &, & > span:not(.anticon) {
        width: 100%;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
      border: none !important;
    }
  }
}
