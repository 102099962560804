@import 'category_tabs';
@import 'log_content';

.ant-pagination {
  margin-bottom: 40px;

  li {
    border-radius: 4px;
    border-color: #d0d1d6;

    @media ( max-width: 500px ) {
      margin-right: 4px;
    }

    a {
      @include textTypography(Inter, 14px, normal, 400, unset, 0, center);
    }

    .ant-pagination-item-link {
      border-radius: 4px !important;
      border-color: #d0d1d6;
    }

    .ant-pagination-item-ellipsis {
      color: #737585;
    }
  }

  .ant-pagination-item-active {
    border-color: $primary;

    a {
      color: $primary;
    }
  }

  .ant-pagination-options {
    display: none;
  }
}