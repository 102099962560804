@import '../responsive';

.ant-modal-root {
  .ant-modal-mask {
    z-index: 1051;
  }

  .ant-modal-wrap {
    z-index: 1051;

    .enjoy__modal {
      width: 90vw !important;
      height: 450px !important;

      @include responsive(mobile-large) {
        width: 500px !important;
      }

      @include responsive(pc-small) {
        width: 758px !important;
      }

      .ant-modal-content {
        background-color: transparent;
        height: 100%;
        width: 100%;

        .ant-modal-body {
          padding: 0;
          height: 100%;
          width: 100%;

          iframe {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}
